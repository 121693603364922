@import "styles/variables.scss";

.root {
  position: relative;
  height: 100vh;
  overflow: hidden;
  overflow-wrap: break-word;

  @media only screen and (min-height: $breakpoint-md) {
    min-height: $breakpoint-md;
  }

  @media only screen and (max-height: $breakpoint-md) {
    min-height: $iphone-5-height;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.2s;
}

.loaded {
  opacity: 1;
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.description {
  width: 100%;
  padding-right: 5px;
  padding-left: 80px;
  margin-bottom: 70px;
  text-align: left;

  @media screen and (max-width: $breakpoint-sm) {
    padding-right: 25px;
    padding-left: 25px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-bottom: 30px;
  }
}

@mixin animation-property($name, $delay, $duration) {
  animation-name: $name;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: forwards;
}

.title {
  max-width: 1024px;
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
  font-size: 74px;
  font-weight: var(--heading-font-weight);
  line-height: 84px;
  color: var(--color-alpha);
  opacity: 0;

  body[class="theme-dark"] & {
    color: var(--color-bravo);
  }

  &.animated {
    @include animation-property($name: translate3d, $delay: 0.5s, $duration: 0.8s);
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 36px;
    line-height: 42px;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: var(--color-alpha);
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0;

  body[class="theme-dark"] & {
    color: var(--color-bravo);
  }

  &.animated {
    @include animation-property($name: fadeIn, $delay: 0.8s, $duration: 1.3s);
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 14px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes translate3d {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transition: all 0.8s ease-out;
    transform: translate3d(0, 0, 0);
  }
}

.arrow-down {
  stroke: var(--color-alpha);

  body[class="theme-dark"] & {
    stroke: var(--color-bravo);
  }
}
