@import "styles/variables.scss";

.modal {
  max-width: 493px;
  padding: 0;

  @media screen and (max-width: $breakpoint-md) {
    max-width: initial;
  }
}

.video {
  display: block;
  object-fit: fill;
  width: 493px;
  min-height: 277px;
  background-color: var(--color-lima);

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: auto;
    min-height: initial;
  }
}

.headline {
  margin-bottom: 15px;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.4;
  color: var(--color-bravo);
  letter-spacing: normal;
}

.slide {
  display: flex;
  flex-direction: column;
  height: auto;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 30px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;

  @media screen and (max-width: $breakpoint-md) {
    display: block;
    padding-top: 0;
    text-align: center;
  }
}

.description {
  margin-bottom: 25px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.57;
  color: var(--color-bravo);
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
}

.button {
  margin-left: 20px;
}

.button-group {
  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.mobile-button-group {
  display: none;

  .button {
    width: 100%;
    margin-left: 0;
  }

  @media screen and (max-width: $breakpoint-md) {
    display: block;
  }
}

.dots {
  display: none;
  margin-top: 35px;
  margin-bottom: 25px;

  @media screen and (max-width: $breakpoint-md) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dot {
  width: 6px;
  height: 6px;
  margin-right: 10px;
  background-color: var(--color-golf);
  border-radius: 50%;

  &:last-child {
    margin-right: 0;
  }
}

.active-dot {
  width: 8px;
  height: 8px;
  background-color: var(--color-bravo);
}
