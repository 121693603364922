.root {
  padding: 60px 0;
  margin: 0 auto;
}

.loader {
  margin: 0 auto;
  width: 25px;
  height: 25px;
}

.content {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  margin: 0 auto;
  max-width: 316px;

  body[class="theme-dark"] & {
    color: #fff;
  }
}
