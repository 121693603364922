@import "styles/variables.scss";

.scene {
  padding-top: 85px;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 35px;
  }
}

.without-title {
  padding-top: 68px;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 40px;
  }
}

.container {
  @media screen and (max-width: $breakpoint-md) {
    padding-right: 8px;
    padding-left: 8px;
  }
}
