.root {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--color-lima);

  &.cursor-pointer {
    cursor: pointer;
  }

  .overlay-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
