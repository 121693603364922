@import "styles/variables.scss";

.root {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-lima);
}

.card {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hidden {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s all;
}

.no-touch:hover {
  cursor: default;
}

.overlay {
  &::before {
    opacity: 0.4;
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: $breakpoint-sm) {
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
