@import "@app/styles/variables.scss";

.no-photos-message {
  padding-top: 90px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.43;
  color: var(--color-charlie);
  text-align: center;
  letter-spacing: normal;
}
