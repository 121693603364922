@import "styles/variables.scss";

.root {
  margin-top: 81px;
  margin-bottom: 71px;

  @media screen and (max-width: $breakpoint-md) {
    margin-top: 47px;
    margin-bottom: 55px;
  }
}

.title {
  color: var(--color-bravo);
  font-weight: var(--heading-font-weight);
  font-size: 44px;
  font-family: var(--heading-font-family);
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 24px;
  }
}

.description {
  max-width: 468px;
  margin-top: 20px;
  margin-right: auto;
  margin-bottom: 22px;
  margin-left: auto;

  color: var(--color-bravo);
  font-weight: 300;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;

  @media screen and (max-width: $breakpoint-md) {
    padding-right: 5%;
    padding-left: 5%;
  }
}

.button {
  display: block;

  margin-right: auto;
  margin-left: auto;
}
