@import "styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  min-height: 768px;
  overflow: hidden;
  overflow-wrap: break-word;

  @media only screen and (max-height: $breakpoint-sm) {
    position: relative;
    min-height: $iphone-5-height;
  }
}

.area {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  max-width: 100%;
  margin: 100px 30px 56px 30px;
  overflow: hidden;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 0;
    margin: 0;
  }
}

.composition {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  transition: 0.2s transform;
  will-change: transform;

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 100%;
    transform: none !important;
  }
}

.cover-container {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
  }
}

.cover {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  vertical-align: top;
  opacity: 0;
  object-fit: cover;
  will-change: transform;
}

.loaded {
  opacity: 1;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 40px;

  @media screen and (max-width: $breakpoint-lg) {
    position: absolute;
    bottom: 30px;
    z-index: 3;
    padding-bottom: 0;
  }
}

.description {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 22px;
  text-align: center;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 0;
    margin-bottom: 25px;
  }
}

@mixin animation-property($name, $delay, $duration) {
  animation-name: $name;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: forwards;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: max-content;
  max-width: 1280px;
  font-family: var(--heading-font-family);
  font-size: 140px;
  font-weight: var(--heading-font-weight);
  line-height: 146px;
  color: var(--color-bravo);
  text-align: center;
  opacity: 0;
  transition:
    1s transform,
    opacity;
  transition-timing-function: cubic-bezier(0.24, 0.5, 0.59, 0.89);
  transform: translate(-50%, -50%);
  will-change: transform, opacity;

  body[class="theme-dark"] & {
    color: var(--common-color-smoke-screen);
  }

  &.animated-title {
    animation: titleAnimation 0.3s forwards;
  }

  @media screen and (max-width: 1439px) {
    max-width: 1100px;
    font-size: 110px;
    line-height: 114px;
  }

  @media screen and (max-width: 1279px) {
    max-width: 900px;
    font-size: 80px;
    line-height: 88px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    z-index: 3;
    width: 100%;
    max-width: 100%;
    padding: 0 25px;
    font-size: 44px;
    line-height: 50px;
    color: var(--color-alpha);
    transition:
      0.1s transform,
      opacity;
    transition-timing-function: linear;

    body[class="theme-dark"] & {
      color: var(--common-color-white);
    }
  }
}

.inverted-title-container {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  text-align: center;
  transform: translate(-50%, -50%);

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.inverted-title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  max-width: 1280px;
  font-family: var(--heading-font-family);
  font-size: 140px;
  font-weight: var(--heading-font-weight);
  line-height: 146px;
  color: var(--color-bravo);
  color: white;
  text-align: center;
  opacity: 0;
  transition:
    1s transform,
    opacity;
  transition-timing-function: cubic-bezier(0.24, 0.5, 0.59, 0.89);
  transform: translate(-50%, -50%);
  will-change: transform, opacity;

  &.animated-title {
    animation: titleAnimation 0.3s forwards;
  }

  @media screen and (max-width: 1439px) {
    max-width: 1100px;
    font-size: 110px;
    line-height: 114px;
  }

  @media screen and (max-width: 1279px) {
    max-width: 900px;
    font-size: 80px;
    line-height: 88px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.subtitle {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: var(--color-bravo);
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0;

  body[class="theme-dark"] & {
    color: var(--common-color-white);
  }

  &.animated {
    @include animation-property($name: fadeIn, $delay: 0.8s, $duration: 1.3s);
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
    color: var(--color-alpha);

    body[class="theme-dark"] & {
      color: var(--common-color-white);
    }
  }
}

.scroll-down {
  position: relative;
  cursor: pointer;
  opacity: 0;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400%;
    height: 200%;
    content: "";
    transform: translate(-50%, -50%);
  }

  &.animated {
    @include animation-property($name: fadeIn, $delay: 1.5s, $duration: 0.2s);
  }
}

.arrow-down {
  @media screen and (max-width: $breakpoint-lg) {
    path {
      stroke: #fff;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes translate3d {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transition: all 0.8s ease-out;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes titleAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
