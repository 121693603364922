.wrapper {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-eerie-black);
}

.text {
  font-size: 12px;
  letter-spacing: 0;
  color: var(--common-color-smoke-screen);
}

.date {
  color: var(--common-color-white);
}
