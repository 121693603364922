@import "styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.title {
  color: var(--color-bravo);
  margin-bottom: 17px;
  font-size: 20px;
  font-weight: 300;
}

.text {
  margin-bottom: 35px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.43;
  color: var(--color-charlie);

  @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-lg) {
    max-width: 70%;
  }
}

.button {
  position: relative;
  margin-top: 15px;
}

.error {
  border: 1px solid var(--color-kilo) !important;
}

.error-message {
  font-size: 11px;
  font-weight: 500;
  color: var(--color-kilo);
  letter-spacing: normal;
}
