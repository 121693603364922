// +Breakpoints
$breakpoint-xs: 320px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;

// -Breakpoints

// +Devices
$iphone-5-height: 454px;
$spec-tablet: 1079px;
$spec-mobile: 440px;

// -Devices
