@import "styles/variables.scss";

.root {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.content {
  background-color: var(--spec-color-white);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 25px;
  text-align: center;

  button {
    max-width: 100%;
    width: 100%;
  }
}

.title {
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  color: var(--color-black);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  white-space: pre-line;
}

.description {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  white-space: pre-line;
}
