@import "styles/variables.scss";

.title {
  max-width: 1440px;
  font-family: var(--heading-font-family);
  color: var(--color-black);
  font-size: 44px;
  font-weight: var(--heading-font-weight);
  line-height: 50px;
  text-align: center;
  overflow-wrap: break-word;
  margin: 0 auto 55px auto;

  &.with-description {
    margin: 0 auto 20px auto;
  }

  &.with-hiding {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    min-height: 53px;

    .title-text {
      position: relative;
      right: -25px;
      transition: right 0.35s ease-out;
      @media screen and (max-width: $breakpoint-md) {
        right: 0;
      }
    }
  }

  & div {
    opacity: 0;
    transition: opacity 0.35s ease-out;
    @media screen and (max-width: $breakpoint-md) {
      opacity: 1;
    }
  }

  &.is-hover-and-can-hidden {
    .title-text {
      right: 0;
      transition: right 0.3s ease-out;
    }

    & div {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    font-size: 24px;
    line-height: 31px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin: 0 auto 32px auto;

    &.with-description {
      margin: 0 auto 13px auto;
    }
  }
}

.description {
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.5;
  color: var(--color-bravo);
  text-align: center;
  letter-spacing: normal;
  margin-bottom: 61px;

  @media screen and (max-width: $breakpoint-md) {
    max-width: 90%;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-bottom: 33px;
  }
}
