@import "styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  padding-top: 100px;
  overflow: hidden;
  overflow-wrap: break-word;

  @media only screen and (max-height: $breakpoint-md) {
    min-height: $iphone-5-height;
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding-top: 40px;
  }
}

.picture-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  margin: 0 30px;
  overflow: hidden;

  @media screen and (max-width: $breakpoint-lg) {
    flex-grow: 10;
    flex-shrink: 1;
  }
}

.cover {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  vertical-align: top;
  opacity: 0;
  transition: opacity 0.2s;
  transform: translate(-50%, -50%);
  object-fit: contain;

  @media screen and (max-width: $breakpoint-lg) {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.loaded {
  opacity: 1;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 40px;
  margin-top: 56px;

  @media screen and (max-width: $breakpoint-lg) {
    flex-grow: 1;
    flex-shrink: 10;
    justify-content: space-between;
    padding: 0 25px 30px 25px;
    margin-top: 30px;
  }
}

.description {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 97px;
  text-align: center;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 0;
    margin-bottom: 66px;
  }
}

@mixin animation-property($name, $delay, $duration) {
  animation-name: $name;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: forwards;
}

.title {
  max-width: 1024px;
  margin-bottom: 20px;
  font-family: var(--heading-font-family);
  font-size: 74px;
  font-weight: var(--heading-font-weight);
  line-height: 84px;
  color: var(--color-bravo);
  text-align: center;
  opacity: 0;

  &.animated {
    @include animation-property($name: translate3d, $delay: 0.5s, $duration: 0.8s);
  }

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 15px;
    font-size: 36px;
    line-height: 42px;
  }
}

.subtitle {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: var(--color-bravo);
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0;

  &.animated {
    @include animation-property($name: fadeIn, $delay: 0.8s, $duration: 1.3s);
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 14px;
  }
}

.scroll-down {
  position: relative;
  cursor: pointer;
  opacity: 0;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400%;
    height: 200%;
    content: "";
    transform: translate(-50%, -50%);
  }

  &.animated {
    @include animation-property($name: fadeIn, $delay: 1.5s, $duration: 0.2s);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes translate3d {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transition: all 0.8s ease-out;
    transform: translate3d(0, 0, 0);
  }
}

.arrow-down {
  stroke: var(--color-alpha);
}
