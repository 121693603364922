.input {
  display: block;
  color: var(--color-black);
  width: 100%;
  max-width: 480px;
  margin-bottom: 15px;
  padding: 16px 21px;
  font-size: 14px;
  border: solid 1px var(--color-hotel);
  border-radius: 0;
  background-color: var(--color-alpha);
  outline: none;
  -webkit-appearance: none;
  transition: 0.2s border-color;

  // Отключает апскейл при устновки фокуса в инпут safari
  @supports (-webkit-touch-callout: none) {
    width: 114.2% !important;
    min-height: 62.8px !important;
    font-size: 16px !important;
    transform: scale(0.875) !important;
    transform-origin: left !important;
  }

  &:focus {
    border-color: var(--color-echo);
  }

  &::placeholder {
    color: var(--color-delta);
  }
}

.label {
  position: relative;
  display: block;
  margin-bottom: 15px;

  .input {
    margin-bottom: 0;
  }
}

.postfix {
  position: absolute;
  top: 50%;
  right: 20px;

  display: flex;

  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }
}

.error {
  border-color: var(--color-vineyard-autumn);
}
